:root {
  /* Primary Colors */
  --color-primary: #d9b373;
  --color-primaryLight: #fcfcfc;
  --color-primaryDark: #4a4234;
  --color-primaryContrast: #000000;

  /* Secondary Colors */
  --color-secondary: #fff7e9;
  --color-secondaryLight: #fcfcfc;
  --color-secondaryDark: #bfb9af;
  --color-secondaryContrast: #ffffff;

  /* Background Colors */
  --color-background: #111315;
  --color-backgroundLight: #1a1d1f;
  --color-backgroundLightGray: #1a1d1f;
  --color-backgroundDark: #151719;
  --color-borderBackgroundLight: #6f767e;

  /* Success Colors */
  --color-successColor: #40c86f;
  --color-successColorLight: #cff7dd;
  --color-successColorDark: #227440;
  --color-successColorContrast: #000000;
}
