@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "ModAberMano";
  src: url("../src/assets/fonts/mod-aber-mono-regular-webfont.woff") format("woff2"),
    url("./assets/fonts/mod-aber-mono-regular-webfont.woff") format("woff");
  font-weight: regular;
  font-style: regular;
}
